<template>
  <div class="replyArea">
    <el-row :gutter="20">
      <img
        v-if="userInfo && userInfo.id"
        :src="userInfo.avatar"
        class="userAvatar"
        alt=""
      />
      <el-input
        v-model="comments"
        class="commentInput"
        placeholder="写下你的评论"
      ></el-input>
      <el-button type="text" @click="publishComment">发布</el-button>
    </el-row>
  </div>
</template>
<script>
import { publishComment } from "@/service/company";
import { mapState } from "vuex";
import { Toast } from "vant";
import { publishDiscussion } from "@/service/leetcodeClassification";
export default {
  name: "Reply",
  props: {
    type: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      comments: "",
    };
  },
  methods: {
    publishComment() {
      if (this.comments == "") {
        return Toast("评论不能为空！");
      }
      let data = {};
      if (this.$route.query.id) {
        data = {
          content: this.comments,
          type: true,
          superId: this.$route.query.id,
          parentId: 0,
          superType: this.type,
        };
      } else {
        if (this.$route.path == "/liveRoom") {
          data = {
            content: this.comments,
            type: true,
            superId: 1,
            parentId: 0,
            superType: this.type,
          };
        }
        if (this.$route.path == "/leetcodeClassification/discussion") {
          data = {
            content: this.comments,
            pictures: [],
            anonymity: false,
          };
          publishDiscussion(data).then((res) => {
            if (res.success) {
              this.comments = "";
              Toast("发表评论成功！");
              this.$emit("reloadDetail");
              return;
            }
          });
          return;
        }
      }

      publishComment(data).then((res) => {
        if (res.success) {
          Toast("发表评论成功！");
          this.$emit("reloadDetail");
          this.$bus.$emit("updateCommentList", "update");
          this.comments = "";
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
.replyArea {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1500;
  background: #fff;
  padding: 12px 20px;
  border: 1px solid #f2f2f7;
}
.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: top;
  transform: translateY(4px);
}
.commentInput {
  margin-left: 4px;
  width: calc(100% - 120px);
  margin-right: 12px;
}
</style>