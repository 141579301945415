import request from "@/utils/request";

export async function getCompanyResource(params) {
    try {
        return await request({
            url: `/company/careerFair`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

export async function searchCompanys(keyWord) {
    try {
        return await request({
            url: `/company/select?keyWord=${keyWord}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}


export async function browse(id) {
    try {
        return await request({
            url: `/browse/` + id,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}

export async function alsoAsk(id, type) {
    try {
        return await request({
            url: `/interviewExperienceDetail/alsoAsk/${id}/${type}`,
            method: 'post'
        });
    } catch (error) {
        return {};
    }

}

export async function getQuestionDetail(id) {
    try {
        return await request({
            url: `/interviewExperienceDetail/${id}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

export async function getInterviewDetail(id) {
    try {
        return await request({
            url: `/interviewExperience/${id}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}

export async function publishComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}