import request from '../utils/request';


export async function getComments(parentId, current, type) {
    try {
        return await request({
            url: `/publicComment/comment/${parentId}?current=${current?current:1}&type=${type}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}


export async function publishComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}

export async function getChildComments(commentId, page) {
    try {
        return await request({
            url: `/publicComment/commentChildren/${commentId}?current=${page? page :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}


export async function getTrendsComments(parentId, current, type) {
    try {
        return await request({
            url: `/publicComment/noticeComment/${parentId}?current=${current}&type=${type}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

export async function getTrendDetail(parentId, type) {
    try {
        return await request({
            url: `/publicComment/commentLike/${parentId}?type=${type}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}