import request from "@/utils/request";

export async function getLCClasses() {
    try {
        return await request({
            url: `/clockInGroup/classify?templateId=1`,
            method: "get",
            data: {},
        });
    } catch (error) {
        return {};
    }
}

export async function getClassDetail(id) {
    try {
        return await request({
            url: `/clockInTemplate/${id}`,
            method: "get",
            data: {},
        });
    } catch (error) {
        return {};
    }
}


export async function getSequenceTypes(id) {
    try {
        return await request({
            url: `/leetcodeTable/select?parentId=${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}


export async function getSequenceData(id, params) {
    try {
        return await request({
            url: `/leetcodeTable/problem/${id}`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

export async function getDiscussion(params) {
    try {
        return await request({
            url: `/leetcodeNotice`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

export async function browseLeetcode(id) {
    try {
        return await request({
            url: `/browse/leetcode/${id}`,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}


export async function getCodeDetail(id) {
    try {
        return await request({
            url: `/leetcodeProblem/${id}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

export async function publishDiscussion(data) {
    try {
        return await request({
            url: `/leetcodeNotice`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}